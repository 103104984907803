header {
  padding-top: calc(var(--container) / 2);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 250;
  width: 100%;
}
header .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
header .container .menu-btn {
  cursor: pointer;
}
header .container .menu-btn .btn-content {
  height: calc(var(--paragraph) * 1.1);
  overflow: hidden;
  z-index: 1000;
}
header .container .menu-btn .btn-content div {
  font-size: var(--paragraph);
  text-align: center;
}


