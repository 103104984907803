:root {
  --bg: #060609;
  --font: #F2F2F2;
  --container:3.63rem;
  --title: 1.4rem;
  --paragraph: 1.1rem;
}

@font-face {
  font-family: regular;
  src: url('./assets/fonts/Chopin/Chopin-Trial-Regular.otf');
  font-display: swap;
}
@font-face {
  font-family: semibold;
  src: url('./assets/fonts/Chopin/Chopin-Trial-SemiBold.otf');
  font-display: swap;
}
@font-face {
  font-family: brand;
  src: url('./assets/fonts/Rosca/rosca.otf');
  font-display: swap;
}

body, * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background: var(--bg);
  color: var(--font);
  font-family: regular;
}

a {
  text-decoration: none;
}

ul {
  list-style: none;
}

.container {
  margin: 0 var(--container);
}

@media (max-width:600px){
  :root {
  --container:1.63rem;
  }
}

.title {
  font-size: var(--title);
  display: flex;
  align-items: center;
  gap: 1rem;
  font-family: semibold;
}
.brand-title {
  font-family: brand;
  font-weight: 100;
}
.title:before {
  width: 10px;
  height: 10px;
  display: block;
  background-color: var(--font);
  content: '';
  border-radius: 50%;
}

.paragraph {
  font-size: var(--paragraph);
}
::-webkit-scrollbar {
  display: none;
}

.page-content {
  padding-top: 7rem;
}
main .hero {
  width: 100dvw;
  height: 100dvh;
  perspective: 100dvw;
  position: relative;
}
main .hero .image {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
}
main .hero .image:after {
  width: 100%;
  height: 100%;
  display: block;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  background-color: black;
  opacity: .75;
}
main .hero .image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: hue-rotate(175deg) saturate(125%);
  vertical-align: middle;
  transform: scale(1.2);
}
main .hero .content {
  position: absolute;
  inset: 0;
  margin: auto;
  width: fit-content;
  height: fit-content;
}
main .hero .content .name-container {
  display: flex;
  align-items: start;
  gap: 1rem;
}
main .hero .content .name-container .name h1 {
  font-size: 18dvw;
  /* font-size: 17rem; */
  line-height: 110%;
  font-family: brand;
  font-weight: 100;
  transform: translateY(-25%);
}
main .hero .content .name-container .name h1 .name-letters {
  display: flex;
  height: 18dvw;
  align-items: end;
  overflow: hidden;
}
main .hero .content .name-container .name h1 .name-letters div {
  transform: translateY(100%);
}
main .hero .content .name-container .list {
  transform: translateY(calc(50% - .3rem * 4));
}
main .hero .content .name-container .list li {
  font-size: var(--paragraph);
  margin: .3rem 0;
  overflow: hidden;
}

main .hero .content .staggers {
  transform: translateY(100%);
}
main .hero .content .location {
  transform: translateY(-220%) translateX(.3rem);
  overflow: hidden;
}

@media (max-width:768px) {
  main .hero .content .name-container .name h1 {
    font-size: 20dvw;
    transform: translateY(-35%);
  }
  main .hero .content .name-container .name h1 .name-letters {
    height: 27dvw;
  }
}

main .hero .scroll-icon-container {
  width: fit-content;
  margin: auto;
  left: 0;
  right: 0;
  bottom: var(--container);
  position: absolute;
}
main .hero .scroll-icon {
  width: 30px;
  height: 50px;
  display: block;
  margin: auto;
  border-radius: 25px;
  border: 1px solid rgba(255, 255, 255, 0.289);
  position: relative;
}
main .hero .scroll-icon div {
  width: 4px;
  height: 8px;
  border-radius: 5px;
  position: absolute;
  left: 0;
  right: 0;
  top: .75rem;
  margin: auto;
  background-color: var(--font);
}
main .hero .scroll-icon-container p {
  margin-top: 1rem;
  font-size: var(--paragraph);
  opacity: .9;
}

main .about-section {
  height: 100dvh;
  display: block;
  width: 100%;
  overflow: hidden;
  position: relative;
  padding: 5rem 0;
}

main .about-section .container {
  position: relative;
  height: 100%;
}
main .about-section .blur {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(to bottom right, transparent, var(--bg) 30%);
  transform-origin: top left;
  z-index: 10;
  pointer-events: none;
  display: block;
}
main .about-section .container p {
  font-size: 1.4rem;
  width: 75%;
}
main .about-section .image {
  width: 20dvw;
  height: 50dvh;
  position: absolute;
  transform-origin: top right;
  bottom: 0;
  z-index: 15;
  right: 0;
}
main .about-section .image::after {
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  content: '';
  background: var(--bg);
  opacity: .3;
}
main .about-section .image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: saturate(0%);
  vertical-align: middle;
}


main .services-section {
  padding: 4rem 0;
  position: relative;
  z-index: 0;
  height: fit-content;
}
main .services-section  .blur {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  pointer-events: none;
  left: 0;
  background: linear-gradient(to bottom right, transparent, var(--bg) 60%);
  transform-origin: top left;
  transform: scale(1) !important;
  z-index: 10;
  display: block;
}
main .services-section .container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
main .services-section .container .title-container {
  position: relative;
  height: 100%;
}
main .services-section .container .title-container .title {
  position: sticky;
  height: fit-content;
  top: calc(var(--container) * 2);
  left:0;
  z-index: 15;
}
main .services-section .container .service {
  margin: 4rem 0;
}
main .services-section .container .service .icon {
  width: fit-content;
  overflow: hidden;
}
main .services-section .container .service .icon div {
  font-size: calc(var(--title) * .7);
  transform: translateY(100%);
}
main .services-section .container .service h1 {
  margin: 1rem 0;
  overflow: hidden;
}
main .services-section .container .service h1 div {
  transform: translateY(100%);
}
main .services-section .container .service p {
  opacity: 0;
  font-size: var(--paragraph);
}
main .work-section {
  z-index: 15;
  overflow: hidden;
}
main .work-section .title {
  z-index: 15;
}
main .work-section .work-content-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  vertical-align: middle;
}
main .work-section .fluid-swiper-container {
  position: relative
}
main .work-section .fluid-swiper-container svg {
  width: 140dvw;
  content: '';
  position: absolute;
  top: -1rem;
  --skewXVALUE: -10;
  transform: translateY(-25%) skewX(var(--skewXVALUE));
  left: -20dvw;
  z-index: 10;
  border-radius: 60%;
}
main .work-section .fluid-swiper-container .svg2 {
  transform: scaleY(-1) skewX(10);
  bottom: -5rem;
  top: initial;
}
/* main .work-section .fluid-swiper-container:hover .svg2 {
  transform: scaleY(-1);
  bottom: -1rem;
} */
main .work-section .work-content-container {
  margin-top: 3rem;
  position: relative;
}
main .work-section .work-content-container .swiper-wrapper {
  perspective: 1000px;
}
main .work-section .work-content-container .swiper-slide {
  position: relative;
  overflow: hidden;
  transition: .4s ease;
  height: 60dvh;
}
main .work-section .work-content-container .swiper-slide-prev {
  transform: perspective(700px) rotateY(6deg) ;
  transform-origin: center left;
}
main .work-section .work-content-container .swiper-slide-next ~ div,main .work-section .work-content-container .swiper-slide-next{
  transform: perspective(700px) rotateY(-6deg) translateX(20%) !important;
  transform-origin: center right;
}
main .work-section .work-content-container .swiper-slide .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
main .work-section .work-content-container .swiper-slide .overlay .overlay-blur {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: .5s ease;
  background-color: rgba(0, 0, 0, 0.234);
  z-index: 1;
  backdrop-filter: blur(10px);
}
main .work-section .work-content-container .swiper-slide button{
  z-index: 10;
  position: absolute;
  inset: 3;
  padding: 1rem 2rem;
  font-size: var(--paragraph);
  opacity: 0;
  overflow: hidden;
  margin: auto;
  background-color: transparent;
  transition: .5s ease;
  border: 1px solid var(--font);
}
main .work-section .work-content-container .swiper-slide button .btn-content-wrapper{
  height: var(--paragraph);
  overflow: hidden;
}
main .work-section .work-content-container .swiper-slide:hover :is(button,.overlay-blur){
  opacity: 1;
}
main .work-section .work-content-container .swiper-slide button .hover {
  background: var(--font);
  width: 100%;
  position: absolute;
  top: 0;
  height: 100%;
  transform: translateY(100%);
  left: 0;
  display: block;
}
main .work-section .work-content-container .swiper-slide button .btn-content-wrapper div {
  /* transition: .5s ease; */
  color: var(--font);
}


@media (max-width:1080px) {
  main .about-section .image {
    width: 50dvw;
    height: 50dvh;
    bottom: 5rem;
  }
  main .about-section .container p {
    font-size: 1.6rem;
    width: 85%;
  }
  main .work-section .work-content-container .swiper-slide {
    height: 30dvh;
  }
}
@media (max-width:860px) {
  main .about-section .image {
    width: 30dvw;
    height: 30dvh;
    bottom: 5rem;
  }
  main .about-section .container p {
    font-size: 1.6rem;
    width: 85%;
  }
}
@media (max-width:768px) {
  main .services-section .container {
    grid-template-columns: 1fr;
  }
}
@media (max-width:600px) {
  main .hero .content .name-container .name {
    height: 20dvw;
  }
  main .hero .content .name-container .name h1 {
    font-size: 24dvw;
    
    transform: translateY(-35%);
  }
  main .hero .content .name-container .name h1 .name-letters {
    height: 27dvw;
  }
  main .hero .content .name-container {
    flex-direction: column;
  }
  main .hero .content .name-container .list {
    transform: translateY(0);
  }
  main .hero .content .location {
    transform: translateY(0%);
    overflow: hidden;
  }
  main .about-section .container p {
    font-size: 1rem;
    width: 100%;
  }
  main .about-section .image {
    width: 50dvw;
    height: 30dvh;
    bottom: -2rem;
  }
  main .work-section .work-content-container .swiper-slide {
    height: 30dvh;
  }
}

main .contact-section {
  z-index: 15;
  padding: 5rem 0;
}
main .contact-section .container {
  display: flex;
  align-items: center;
  justify-content: center;
}
main .contact-section ul li a {
  color: var(--font);
  font-size: var(--paragraph);
}

main .contact-section ul {
  list-style: none;
  margin-left: 3rem;
}
main .contact-section ul li {
  margin: .75rem 0;
}
main .contact-section ul li .link-content {
  height: calc(var(--paragraph) + .3rem);
  overflow: hidden;
  position: relative;
}
main .contact-section ul li a .link-content div:nth-child(1){
  position: absolute;
  top: 0;
  left: 0;
  clip-path: inset(0 100% 0 0);
  transition: .4s ease;
}

main .contact-section ul li:hover a .link-content div:nth-child(1){
  clip-path: inset(0 0 0 0);
}
main .contact-section ul li a .link-content div:nth-child(2){
  opacity: 0.5;
}
main .contact-section ul li .location {
  font-family: semibold;
}

main .phrase h1 {
  font-size: 22dvw;
  font-family: semibold;
}
main .phrase h1 span {
  font-family: regular;
  font-weight: 100;
  opacity: .6;
}

@media (max-width:1080px){
  main .phrase h1 {
      font-size: 21dvw;
  }
}

@media (max-width:600px){
  main .phrase h1 {
      font-size: 20dvw;
      text-align: center;
  }
}