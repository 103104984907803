
.navbar-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100dvw;
    height: 100dvh;
    background-color: var(--bg);
    z-index: 200;
    --x1: 100%;
    --x2: 0%;
    clip-path: inset(var(--x1) 0 var(--x2) 0);
    /* opacity: .5; */
  }
  
  .navbar-container .grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: calc(var(--container) * 2);
    align-items: center;
    position: relative;
  }
  .navbar-container .grid .profile {
    position: relative;
    overflow: hidden;
  }
  .navbar-container .grid .profile:after {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: .5;
    background-color: var(--bg);
    content: '';
  }
  .navbar-container .grid img {
    width: 100%;
    height: 100dvh;
    filter: saturate(0);
    object-fit: cover;
    vertical-align: middle;
  }
  .navbar-container .grid img:not(:nth-child(1)){
    position: absolute;
    top: 0;
    left: 0rem;
    opacity: .5;
  }
  
  .navbar-container nav {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100dvh - var(--container) * 2);
  }
  .navbar-container nav ul li {
    margin: 1.5rem 0;
  }
  .navbar-container nav ul a {
    color: var(--font);
    font-size: calc(var(--title) * 1.5);
  }
  .navbar-container nav ul li .link-content {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  .navbar-container nav ul li .dot {
    width: 10px;
    height: 10px;
    display: block;
    opacity: 0;
    background: var(--font);
    border-radius: 50%;
    transition: .4s opacity;
  }
  .navbar-container nav ul li:hover .dot {
    opacity: 1;
  }
  .navbar-container .contacts {
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap: 1rem;
  }
  .navbar-container .contacts img{
    width: 13px;
    height: 13px;
  }
  .navbar-container .contacts ul li {
    margin: .6rem 0;
  }
  .navbar-container .contacts ul li .contact-link-wrapper {
    height: calc(var(--paragraph) + .3rem);
    overflow: hidden;
  }
  .navbar-container .contacts ul li .link-wrapper-child {
    display: flex;
    align-items: center;
    transition: .4s ease;
    gap: 1rem;
    font-size: var(--paragraph);
  }
  .navbar-container .contacts ul li:hover .link-wrapper-child {
    transform: translateY(-100%);
  }
  .navbar-container .contacts ul li .link-wrapper-child:nth-child(1){
    opacity: .5;
  }
  .navbar-container .contacts ul li .contact-location {
    font-family: semibold;
    font-size: var(--paragraph);
  }
@media (max-width:600px){
    .navbar-container .grid {
      grid-template-columns: 1fr;
      grid-template-rows: 30dvh 70dvh;
      gap: 0rem;
    }
  .navbar-container .profile {
    height: 50dvh;
  }
  .navbar-container .grid img {
    height: 70dvh;
    object-position: bottom;
  }
  .navbar-container nav {
    height: 60dvh;
    padding: 2rem;
    margin-top: 0;
  }
  .navbar-container .contacts {
    grid-template-columns: 1fr;
  }
}